<!-- App.vue -->
<template>
  <div id="app">
    <div class="container w-container" v-if="!isLoading">
      <div class="navbar-split">
        <div
          id="w-node-fbcb3a7f-3a6d-b2bd-259f-da050f0793a7-0f0793a4"
          class="navbar-left"
        >
          <a
            href="https://www.adan.careers/"
            aria-current="page"
            class="brand w-nav-brand w--current"
            ><img
              src="https://assets-global.website-files.com/65ac2326d1813aba6e5c762f/65ac2326d1813aba6e5c7672_65abb9aab391d6db6a476ac7_231010_ADC_LOGOS_RGB_AC_TONE%20OF%20VOICE_White_RGB.svg"
              loading="lazy"
              width="NaN"
              alt=""
              class="image-23"
          /></a>
          <nav role="navigation" class="nav-menu w-nav-menu"></nav>
          <a href="https://jobs.adan.careers" class="nav-link hide-mobile"
            >Jobs</a
          >
          <a
            href="https://www.adan.careers/event-kalender"
            class="nav-link hide-mobile"
            >Events</a
          >
          <a
            href="https://www.adan.careers/content-hub"
            class="nav-link hide-mobile"
            >Content hub</a
          >
        </div>
        <div
          id="w-node-fbcb3a7f-3a6d-b2bd-259f-da050f0793f6-0f0793a4"
          class="regular-navbar-right"
        >
          <a
            href="https://www.adan.careers/for-companies"
            class="nav-link hide-mobile nobreak"
            >For Companies</a
          >
          <a
            href="https://www.adan.careers/about-us"
            class="nav-link hide-mobile"
            >About us</a
          >
          <a
            href="https://www.adan.careers/upload-cv"
            class="green-button hide-mobile hide w-button"
            >UPLOAD CV</a
          >
          <div class="menu-button w-nav-button">
            <img
              src="https://assets-global.website-files.com/65ac2326d1813aba6e5c762f/65ac2326d1813aba6e5c76a3_Menu.svg"
              loading="lazy"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <router-view></router-view>
    <!-- Verwendet als Platzhalter für Routen-Komponenten -->
    <div class="container w-container" v-if="!isLoading">
      <div class="card-footer">
        <div
          id="w-node-_14cfb451-260a-09a2-235f-445bbeb8f3b8-beb8f3b5"
          class="card-footer-content"
        >
          <div>
            <h3 class="heading-10">
              #1 Career platform for <br />Black and People of <br />Color in
              Europe.
            </h3>
          </div>
          <a
            href="https://www.adan.careers/"
            aria-current="page"
            class="w-inline-block w--current"
            ><img
              src="https://assets-global.website-files.com/65ac2326d1813aba6e5c762f/65ac2326d1813aba6e5c7672_65abb9aab391d6db6a476ac7_231010_ADC_LOGOS_RGB_AC_TONE%20OF%20VOICE_White_RGB.svg"
              loading="lazy"
              width="310"
              alt=""
              class="image-11"
          /></a>
        </div>
        <div
          id="w-node-_14cfb451-260a-09a2-235f-445bbeb8f3ca-beb8f3b5"
          class="card-footer-links"
        >
          <div
            id="w-node-_1990e183-d3da-84ab-7964-7cad5c752269-beb8f3b5"
            class="footer-link-column"
          >
            <h5 class="no-space-bottom less-heigth">Homepages</h5>
            <a
              href="https://www.adan.careers/"
              aria-current="page"
              class="footer-link w--current"
              >Home</a
            >
            <a href="https://jobs.adan.careers" class="footer-link">Jobs</a>
            <a
              href="https://www.adan.careers/event-kalender"
              id="w-node-_1990e183-d3da-84ab-7964-7cad5c752270-beb8f3b5"
              class="footer-link"
              >Events</a
            >
            <a
              href="https://www.adan.careers/about-us"
              id="w-node-_5e3eb220-37be-93ab-26a1-360c52ab014d-beb8f3b5"
              class="footer-link"
              >About Us</a
            >
          </div>
          <div
            id="w-node-a7286be2-1261-a677-6bee-d41e110d820c-beb8f3b5"
            class="footer-link-column"
          >
            <h5 class="no-space-bottom less-heigth">Other</h5>
            <a href="https://www.adan.careers/content-hub" class="footer-link"
              >Content Hub</a
            >
            <a
              href="mailto:info@adan-impact.com"
              id="w-node-a7286be2-1261-a677-6bee-d41e110d8213-beb8f3b5"
              class="footer-link"
              >Contact us</a
            >
            <a href="https://www.adan.careers/impressum" class="footer-link"
              >Imprint</a
            >
            <a href="https://www.adan.careers/datenschutz" class="footer-link"
              >Data Protection</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      // Initialisieren isLoading als true
      isLoading: true,
    };
  },
  mounted() {
    // Du könntest hier einen API-Aufruf oder andere asynchrone Operationen durchführen.
    // Für dieses Beispiel simulieren wir einen einfachen Ladevorgang mit setTimeout
    setTimeout(() => {
      this.isLoading = false; // Setzt isLoading auf false, um anzuzeigen, dass der Ladevorgang abgeschlossen ist
    }, 1000); // Angenommen, der Hauptinhalt ist nach 1 Sekunde geladen
  },
};
</script>

<style>
@import "./components/styles/normalize.css";
@import "./components/styles/webflow.css";
@import "./components/styles/adan-careers.webflow.css";
</style>
