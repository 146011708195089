<template>
  <div>
    <div v-if="isLoading" class="spinner-container">
      <div class="spinner"></div>
    </div>
    <div v-if="jobDetails && jobDetails.properties" :class="{ 'is-hidden': isLoading }" class="main-content">
      <div class="job-details-page">
        <div class="green-section _40px-top">
          <div class="container padding w-container">
            <div class="job-post-thirds">
              <div class="job-post-card">
                <div>
                  <div class="horizontal-content">
                    <h5 class="no-space-bottom green-text">
                      {{ jobDetails.properties.category.select.name }}
                    </h5>
                    <h5 class="no-space-bottom green-text">
                    |  {{ jobDetails.properties.company.rich_text[0].text.content }}
                    </h5>
                  </div>
                  <h1 class="space-top-tiny">
                    {{ jobDetails.properties.job_title.title[0].text.content }}
                  </h1>
                </div>
                <div class="job-card-foot">
                  <div class="horizontal-content center vertical-mobile">
                    <div class="job-tag">
                      {{ jobDetails.properties.employment_type.select.name }}
                    </div>
                    <div class="job-tag">
                      {{
                        jobDetails.properties.location.rich_text[0].text.content
                      }}
                    </div>
                    <div class="job-tag">
                      {{ jobDetails.properties.level.select.name }}
                    </div>
                  </div>
                  <div class="job-date">
                    {{
                      new Date(
                        jobDetails.properties.created_time.created_time
                      ).toLocaleDateString()
                    }}
                  </div>
                </div>
                <router-link to="/" class="green-button fixed w-button"
                  >Back to Jobs</router-link
                >
              </div>
              <div class="company-info-card no-top-margin">
                <div class="company-info-card-head">
                  <div class="horizontal-content">
                    <img
                      :src="jobDetails.properties.companyImageLink.url"
                      alt="Company Logo"
                    />
                  </div>
                </div>
                <div class="company-info-card-foot">
                  <!-- Hier können weitere Unternehmensinformationen eingefügt werden -->
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="underline-section">
          <div class="container tiny w-container">
            <div class="space-top">
              <div class="rich-text w-richtext">
                <p
                  v-html="
                    formatRichText(jobDetails.properties.text_intro.rich_text)
                  "
                ></p>
              </div>
              <h3 class="heading-11">Your Profile</h3>
              <div class="rich-text w-richtext">
                <p
                  v-html="
                    formatRichText(
                      jobDetails.properties.text_yourProfile.rich_text
                    )
                  "
                ></p>
              </div>
              <h3 class="heading-11">About Us</h3>
              <div class="rich-text w-richtext">
                <p
                  v-html="
                    formatRichText(jobDetails.properties.text_aboutUs.rich_text)
                  "
                ></p>
              </div>
              <h3 class="heading-11">Your Tasks</h3>
              <div class="rich-text w-richtext">
                <p
                  v-html="
                    formatRichText(
                      jobDetails.properties.text_yourTasks.rich_text
                    )
                  "
                ></p>
              </div>
              <div class="space-top-small">
                <a
                  :href="jobDetails.properties.applicationLink.url"
                  class="black-button big full-width w-button"
                  target="_blank"
                  @click="trackApplicationClick"
                  >Apply now</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import { useRoute } from "vue-router";
import axios from "axios";

export default {
  setup() {
    const isLoading = ref(true);
    const route = useRoute();
    const jobDetails = ref(null);

    const fetchJobDetails = async (jobId) => {
      isLoading.value = true;

      try {
        const response = await axios.get(
          `https://adan-secureserver.mainly-dev.de:3000/jobs/${jobId}`
        );
        jobDetails.value = response.data;
      } catch (error) {
        console.error("Fehler beim Abrufen der Jobdetails: ", error);
      } finally {
        isLoading.value = false;
        trackPageView();
      }
    };

    const trackPageView = async () => {
      try {
        await axios.post(
          `https://adan-secureserver.mainly-dev.de:3000/jobs/${jobDetails.value.id}/view`
        );
      } catch (error) {
        console.error("Fehler beim Tracken des Seitenaufrufs:", error);
      }
    };

    // Rufe trackPageView am Ende von fetchJobDetails auf

    const trackApplicationClick = async () => {
      try {
        await axios.post(
          `https://adan-secureserver.mainly-dev.de:3000/jobs/${jobDetails.value.id}/apply`
        );
      } catch (error) {
        console.error("Fehler beim Tracken des Bewerbungslink-Klicks:", error);
      }
    };

    watchEffect(() => {
      const jobId = route.params.id;
      if (jobId) {
        fetchJobDetails(jobId);
      }
    });

    const formatRichText = (richTextArray) => {
      return richTextArray
        .map((textItem) => {
          // Ersetze \n durch <br> im Textinhalt
          return textItem.text.content.replace(/\n/g, "<br>");
        })
        .join("");
    };

    return {
      isLoading,
      jobDetails,
      formatRichText,
      trackApplicationClick,
    };
  },
};

// IM LIVEBETRIEB LOCALHOST WIEDER AUF
// https: // 172. 104. 134. 243 oder
// https:// adan- secureserver . mainly-dev.de
// ÄNDERN
</script>

<style scoped>
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* oder 'absolute', abhängig vom Kontext */
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(255, 255, 255); /* leicht transparenter Hintergrund */
  z-index: 10; /* Stellt sicher, dass der Spinner über anderen Inhalten liegt */
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-left-color: #a4d7d4;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.is-hidden {
  display: none;
}
</style>
